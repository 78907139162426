import  React from 'react';
import { Link } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Autoplay, EffectFade, Navigation, Thumbs, Pagination} from 'swiper';
import {Link as GoLink} from 'react-scroll/modules';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Image from '../components/Image';
import ListBrand from '../components/list-brand';
import ListLink from '../components/list-link';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation, Thumbs, EffectFade, Autoplay, Pagination]);

const GallerySlide = (props) => {
  const data = props;
  const sliderList = [];
  for (let i = 0; i < data.imgLength; i++) {
    sliderList.push(
      <SwiperSlide key={`${i}`}>
        <Image filename={`case-${data.case}-${i + 1}`} alt={`導入事例-${data.case}-${i + 1}枚目画像`} />
      </SwiperSlide>
    );
  }
  return (
    <>
    <Swiper
      speed={1000}
      slidesPerView={1}
      navigation
      pagination={{clickable:true}}
    >
      {sliderList}
    </Swiper>
    </>
  );
};

const WorksPage = () => (
    <Layout page="works-page">
      <Seo title="WORKS"/>
      <section className="page-head">
        <ListBrand/>
        <ListLink/>
        <h2 className="main-title"><span>CASE STUDY</span></h2>
      </section>
      <section className="page-content flex-layout">
        <div className="main-content">
          <div className="content-block" id="cases">
            <h3 className="sub-title"><span>導入事例</span></h3>
          </div>
          <div className="content-block left-end md:py-16 py-8 md:pr-20 pr-5">
          <div className="slider">
              <p className="pb-2"><a href="https://f-yamakana.com/" target="_blank" rel="noreferrer" className="c-yellow">
              宮崎県 ヤマカナ様 ALLEGRA500</a></p>
              <GallerySlide
                case={35}
                imgLength={1}
              />
              <Link className="btn full yellow" to="/brand/leda/detail/?id=ALLEGRA500">
              ALLEGRA500
              </Link>
          </div>
          <div className="slider">
              <p className="pb-2"><a href="https://kankyo-fuji.com/business/yukiguni-stove.html" target="_blank" rel="noreferrer" className="c-yellow">
              岐阜県 富士ストーブ様</a></p>
              <GallerySlide
                case={34}
                imgLength={1}
              />
              <Link className="btn full yellow" to="/brand/morso/detail/?id=7110CB">
                morso7110CB
              </Link>
          </div>
          <div className="slider">
              <p className="pb-2"><a href="https://kankyo-fuji.com/business/yukiguni-stove.html" target="_blank" rel="noreferrer" className="c-yellow">
              富士ストーブ様</a></p>
              <GallerySlide
                case={33}
                imgLength={1}
              />
              <Link className="btn full yellow" to="/brand/leda/detail/?id=ALLEGRA500">
                ALLEGRA500
              </Link>
          </div>
          <div className="slider">
              <p className="pb-2">I様</p>
              <GallerySlide
                case={32}
                imgLength={1}
              />
              <Link className="btn full yellow" to="/brand/leda/detail/?id=ALLEGRA500">
                ALLEGRA500
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2"><a href="https://makisutobu-nagomi.com/" target="_blank" rel="noreferrer" className="c-yellow">熊本県薪ストーブなごみ様</a></p>
              <GallerySlide
                case={31}
                imgLength={2}
              />
              <Link className="btn full yellow" to="/brand/leda/detail/?id=ALLEGRA500">
                ALLEGRA500
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2">山口県</p>
              <GallerySlide
                case={30}
                imgLength={1}
              />
              <Link className="btn full yellow" to="/brand/morso/detail/?id=7110CB">
                morso7110CB
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2"><a href="http://www.fuegostove.com/" target="_blank" rel="noreferrer" className="c-yellow">大分県薪ストーブ専門店フエゴ様 S様邸</a></p>
              <GallerySlide
                case={29}
                imgLength={1}
              />
              <Link className="btn full yellow" to="/brand/leda/detail/?id=ALLEGRA500">
                ALLEGRA500
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2"><a href="https://rustic-craft.com/" target="_blank" rel="noreferrer" className="c-yellow">島根県ラスティッククラフト様</a></p>
              <GallerySlide
                case={28}
                imgLength={1}
              />
              <Link className="btn full yellow" to="/brand/leda/detail/?id=ALLEGRA350">
                ALLEGRA350
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2">山口県</p>
              <GallerySlide
                case={27}
                imgLength={1}
              />
              <Link className="btn full yellow" to="/brand/leda/detail/?id=ALLEGRA350">
                ALLEGRA350
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2">山口県</p>
              <GallerySlide
                case={26}
                imgLength={2}
              />
              <Link className="btn full yellow" to="/brand/morso/detail/?id=7110CB">
                7110CB
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2"><a href="https://makisutobu-nagomi.com/" target="_blank" rel="noreferrer" className="c-yellow">熊本県薪ストーブなごみ様</a></p>
              <GallerySlide
                case={25}
                imgLength={3}
              />
              <Link className="btn full yellow" to="/brand/leda/detail/?id=ALLEGRA350">
                ALLEGRA350
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2"><a href="https://kobatake.co.jp/" target="_blank" rel="noreferrer" className="c-yellow">広島県株式会社小畠様</a></p>
              <GallerySlide
                case={24}
                imgLength={3}
              />
              <Link className="btn full yellow" to="/brand/leda/detail/?id=ALLEGRA500">
                ALLEGRA500
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2"><a href="http://www.fuegostove.com" target="_blank" rel="noreferrer" className="c-yellow">東京都K様</a></p>
              <GallerySlide
                case={23}
                imgLength={3}
              />
              <Link className="btn full yellow" to="/brand/morso/detail/?id=7443CB">
                7443CB
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2"><a href="http://www.fuegostove.com" target="_blank" rel="noreferrer" className="c-yellow">大分県薪ストーブ専門店フエゴ様 別府市T様邸</a></p>
              <GallerySlide
                case={22}
                imgLength={1}
              />
              <Link className="btn full yellow" to="/brand/leda/detail/?id=ALLEGRA500">
                ALLEGRA500
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2"><a href="https://kobatake.co.jp/" target="_blank" rel="noreferrer" className="c-yellow">広島県株式会社小畠様</a></p>
              <GallerySlide
                case={21}
                imgLength={1}
              />
              <Link className="btn full yellow" to="/brand/leda/detail/?id=ALLEGRA500">
                ALLEGRA500
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2"><a href="http://www.fuegostove.com/" target="_blank" rel="noreferrer" className="c-yellow">大分県薪ストーブ専門店フエゴ様 S邸</a></p>
              <GallerySlide
                case={20}
                imgLength={1}
              />
              <Link className="btn full yellow" to="/brand/morso/detail/?id=7110CB">
                7110CB
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2"><a href="http://www.rustic-craft.com/" target="_blank" rel="noreferrer" className="c-yellow">島根県ラスティッククラフト様</a></p>
              <GallerySlide
                case={19}
                imgLength={2}
              />
              <Link className="btn full yellow" to="/brand/morso/detail/?id=2140CB">
                2140CB
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2">兵庫県O様</p>
              <GallerySlide
                case={18}
                imgLength={1}
              />
              <p className="btn full yellow pointer-events-none">
                1600
              </p>
            </div>
            <div className="slider">
              <p className="pb-2">兵庫県M様</p>
              <GallerySlide
                case={17}
                imgLength={1}
              />
              <Link className="btn full yellow" to="/brand/morso/detail/?id=1630CB">
                1630CB
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2">福岡県O様</p>
              <GallerySlide
                case={16}
                imgLength={3}
              />
              <Link className="btn full yellow" to="/brand/morso/detail/?id=7140CB">
                7140CB
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2"><a href="http://www.rustic-craft.com/" target="_blank" rel="noreferrer" className="c-yellow">島根県ラスティッククラフト様</a></p>
              <GallerySlide
                case={15}
                imgLength={2}
              />
              <Link className="btn full yellow" to="/brand/leda/detail/?id=ALLEGRA500">
                ALLEGRA500
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2">栃木県 K様</p>
              <GallerySlide
                case={14}
                imgLength={2}
              />
              <Link className="btn full yellow" to="/brand/morso/detail/?id=7110CB">
                7110CB
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2">東京都 Y様</p>
              <GallerySlide
                case={13}
                imgLength={1}
              />
              <p className="btn full yellow pointer-events-none">
                S50-45CB
              </p>
            </div>
            <div className="slider">
              <p className="pb-2">秋田県 S様</p>
              <GallerySlide
                case={12}
                imgLength={1}
              />
              <Link className="btn full yellow" to="/brand/morso/detail/?id=3610CB">
                3610CB
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2">岐阜県 W様 <a href="https://www.stove-kikaku.com" target="_blank" rel="noreferrer" className="c-yellow">薪ストーブ企画</a>様施工</p>
              <GallerySlide
                case={11}
                imgLength={1}
              />
              <Link className="btn full yellow" to="/brand/leda/detail/?id=ALLEGRA500">
                ALLEGRA500
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2">静岡県 I様</p>
              <GallerySlide
                case={10}
                imgLength={1}
              />
              <Link className="btn full yellow" to="/brand/morso/detail/?id=1126CB">
                1126CB
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2">千葉県 M様</p>
              <GallerySlide
                case={1}
                imgLength={4}
              />
              <Link className="btn full yellow" to="/brand/morso/detail/?id=1630CB">
                1630CB
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2">東京都 M様</p>
              <GallerySlide
                case={2}
                imgLength={2}
              />
              <Link className="btn full yellow" to="/brand/morso/detail/?id=6150CB">
                6150CB ソープストーン
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2">広島県 I様</p>
              <GallerySlide
                case={3}
                imgLength={2}
              />
              <Link className="btn full yellow" to="/brand/morso/detail/?id=6643CB">
                6643CB
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2">長野県 I様</p>
              <GallerySlide
                case={4}
                imgLength={2}
              />
              <Link className="btn full yellow" to="/brand/morso/detail/?id=2B-C-Standard-CB">
                2B-C satandard
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2">東京都 Y様</p>
              <GallerySlide
                case={5}
                imgLength={2}
              />
              <p className="btn full yellow pointer-events-none">
              S50-45CB サンドストーン
              </p>
            </div>
            <div className="slider">
              <p className="pb-2">愛媛県 M様</p>
              <GallerySlide
                case={6}
                imgLength={3}
              />
              <p className="btn full yellow pointer-events-none">
                S50-40CB
              </p>
            </div>
            <div className="slider">
              <p className="pb-2">千葉県 I様</p>
              <GallerySlide
                case={7}
                imgLength={3}
              />
              <Link className="btn full yellow" to="/brand/morso/detail/?id=3440CB">
                3440CB
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2">大分県 K様</p>
              <GallerySlide
                case={8}
                imgLength={1}
              />
              <Link className="btn full yellow" to="/brand/morso/detail/?id=5660CB">
                5660CB
              </Link>
            </div>
            <div className="slider">
              <p className="pb-2">長野県 S様</p>
              <GallerySlide
                case={9}
                imgLength={2}
              />
              <Link className="btn full yellow" to="/brand/morso/detail/?id=7443CB">
                7443CB
              </Link>
            </div>
          </div>
          <div className="content-block">
            <Link className="btn full white" to="/movie/" id="movie">
              ムービー紹介
            </Link>
            <a className="btn pdf full mt-5" href="/update/pdf/andersen.pdf" target="_blank" id="catalog">
              PDFカタログ ダウンロード
            </a>
          </div>
        </div>
        <div className="side-list">
        <ul className="sticky">
            <li>
              <GoLink className="dot" to="cases" spy smooth duration={800}>
                導入事例
              </GoLink>
            </li>
            <li>
              <GoLink className="c-yellow" to="movie" spy smooth duration={800}>
                ムービー紹介
              </GoLink>
            </li>
            <li>
              <GoLink className="pdf" to="catalog" spy smooth duration={800}>
                PDFカタログ
              </GoLink>
            </li>
          </ul>
        </div>
      </section>

      <section className="bread">
        <ul>
          <li>
            <Link to="/">
              <Image filename="ico/home.png" />
            </Link>
          </li>
          <li>導入事例</li>
        </ul>
      </section>
    </Layout>
);

export default WorksPage;
